
import { defineComponent, reactive, ref } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import constants from '@/utils/constants';

import { PlusOutlined } from '@ant-design/icons-vue';
import ModalWidget from '@/components/widget/ModalWidget.vue';
import SelectWidget from '@/components/form/SelectWidget.vue';
import SearchTable from '@/components/table/SearchTable.vue';
import FormModal from '@/components/composite/FormModal.vue';
import { DataItem } from '@/types/app.global';

export default defineComponent({
  name: 'Agent',
  components: { FormModal, SearchTable, SelectWidget, ModalWidget, PlusOutlined },
  setup() {
    const { https, mapper, message, notify } = useInject();
    const { userMeta } = useGetters();
    const profile = process.env.VUE_APP_PROFILE;
    const counter = ref(0); // 验证码发送倒计时

    const regAgent = () => {
      const params = { ...entity, oid: userMeta.uid, pid: constants.common.brandId, type: constants.enums.userType.agent, token: userMeta.token };
      https?.member.regAgent(params).then(res => {
        if (res.respType === 'OK') {
          modalOpts.visible = false;
          notify?.success('注册代理成功');
        } else {
          notify?.error('注册代理失败：' + res.respMesg);
        }
      }, () => { modalOpts.visible = false; });
    };

    // 弹出框配置
    const modalOpts = reactive({
      title: '开立账户',
      visible: false,
      action: regAgent
    });

    const entity = reactive({
      alias: '',
      phone: '',
      pwd: '',
      oidSms: '',
      oidPwd: '',
      pc: ''
    });

    const smsOpts = reactive({
      visible: true,
      disabled: false
    });

    // 开发环境使用模拟的短信验证码
    if (profile === 'dev' || profile === 'sit') {
      entity.oidSms = '123456';
      smsOpts.visible = false;
    }

    const pcOpts = {
      mapper: ['code', 'name'],
      remote: {
        action: https?.facade.listArea,
        params: {
          parentCode: '000000'
        }
      },
    };

    const tableOpts = {
      pageable: false,
      search: {
        criteria: {
          cols: [
            {
              field: 'uid',
              label: '代理ID',
              choice: true
            },
            {
              field: 'phone',
              label: '手机号',
              choice: true
            },
            {
              field: 'alias',
              label: '用户名',
              choice: true
            }
          ]
        },
        remote: {
          action: https?.member.listUser,
        }
      },
      cols: [
        {
          field: 'uid',
          label: '用户ID'
        },
        {
          field: 'alias',
          label: '用户名'
        },
        {
          field: 'phone',
          label: '手机号'
        },
        {
          field: 'name',
          label: '姓名'
        },
        {
          field: 'real',
          label: '实名状态',
          options: mapper?.member.realStatus
        },
        {
          field: 'live',
          label: '登录状态',
          options: mapper?.member.liveStatus
        },
        {
          field: 'ctime',
          label: '注册时间',
        }
      ],
      commands: [] as any []
    };

    const liveOpts = reactive({
      title: '变更登录状态',
      visible: false,
      action: https?.member.livableAgent,
      content: {
        entity: {},
        cols: [
          {
            field: 'uid',
            visible: false
          },
          {
            field: 'alias',
            label: '用户名',
            disabled: true
          },
          {
            field: 'live',
            label: '登录状态',
            type: 'radio',
            options: [
              { value: '0', label: '允许' },
              { value: '2', label: '禁止' }
            ],
            required: true
          }
        ]
      }
    });

    const countdown = () => {
      counter.value = 60;
      const t = setInterval(() => {
        counter.value -= 1;
        if (counter.value === 0) {
          clearInterval(t);
        }
      }, 1000);
    };

    const sendSms = () => {
      smsOpts.disabled = true;
      https?.member.sendRegSmsCode({ oid: userMeta.uid, token: userMeta.token }).then(res => {
        smsOpts.disabled = false;
        if (res.respType === 'OK') {
          countdown();
          message?.success('验证码已发送');
        } else {
          message?.warning('验证码发送失败: ' + res.respMesg);
        }
      }, () => { smsOpts.disabled = false; });
    };

    const openModal = () => {
      // 打开弹出框重置entity数据
      entity.alias = '';
      entity.phone = '';
      entity.pwd = '';
      entity.oidSms = '';
      entity.pc = '';
      if (profile === 'dev' || profile === 'sit') {
        entity.oidSms = '123456';
      }

      modalOpts.visible = true;
    };

    const showLivable = (data: DataItem) => {
      liveOpts.content.entity = { uid: data.uid, alias: data.alias, live: '' };
      liveOpts.visible = true;
    };

    tableOpts.commands.push({ label: '激活/注销', perm: 'livable', action: showLivable });
    return { tableOpts, modalOpts, entity, pcOpts, liveOpts, smsOpts, phone: userMeta.phone, counter, sendSms, openModal };
  }
});
